export function Background() {
    return({
        backgroundColor: '#00150B',
        fontFamily: 'Fortuna',
        height: '100%',
        width: '100%',
    })
}


