import { useEffect, useRef, useState } from "react";
import {NavigationComponent} from "../../components/NavigationComponent";
import { Background, Test, TestContentBlock } from "./style";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DEFAULT_API_URL } from "../../App";
import UserModal from "../../components/UserModal";
import "./style.css"
import {createAxiosInstance} from "../../utils/apiUtils";

interface QuizPart {
    id: number;
    title: string;
    timeInMin?:number;
    questionCount?: number;
    quizPart: {
        description: string;
    };
}

interface Quiz {
    id: number;
    title: string;
    questionCount?: number;
    timeInMin?:number;
    quizParts?: QuizPart[];
}

const Tests = () => {
    const [chosenTest, setChosenTest] = useState<any>(null);
    const [opacityCondition, setOpacityCondition] = useState(false);
    const tests = useRef<Quiz[]>([]);
    const navigate = useNavigate();
    const [modalUserInfoIsOpen, setModalUserInfoIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [selectedPartId, setSelectedPartId] = useState<number>();
    const [quizPartsId, setQuizPartsId] = useState<number>();

    function getTests() {
        axios.get(DEFAULT_API_URL + "/quiz/types")
            .then(response => {
                tests.current = response.data;
                if (tests.current && tests.current.length > 0) {
                    setChosenTest(tests.current[0].title);
                }
            })
            .catch(error => {
                console.error("Error fetching quiz data:", error);
            });
    }

    useEffect(() => {
        getTests();
        const isLogged = localStorage.getItem("isLogged") === "true"
        const testLoginEnabled = localStorage.getItem("testLoginEnabled") === "true";
        if (!testLoginEnabled) {
            setModalUserInfoIsOpen(false);
        } else {
            setModalUserInfoIsOpen(!isLogged);
        }
    }, []);

    const handleSelectQuizPart = (partId: number) => {
        setQuizPartsId(partId)
    };

    const handleMouseEnterChapter = (event: React.MouseEvent<HTMLDivElement>) => {
        setChosenTest(Number(event.currentTarget.id));
        setTimeout(() => { setOpacityCondition(true) }, 300);
    };

    const handleMouseLeaveChapter = (event: React.MouseEvent<HTMLDivElement>) => {
        setChosenTest(-1);
        setOpacityCondition(false);
    };

    const handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchQuery(value);
        axios.get(DEFAULT_API_URL + `/session/students/search?fullName=${value}`)
            .then(response => {
                setSearchResults(response.data.content);
            })
            .catch(error => {
                console.error("Error fetching student data:", error);
            });
    };

    const handleUserSelect = (user: any) => {
        setSelectedUser(user.login);
        setSearchQuery(user.fullName)
    };

    const submitUser = (user: any) => {
        createAxiosInstance().post(DEFAULT_API_URL + `/session/authorization?login=${user}`)
            .then(() => {
                localStorage.setItem("isLogged", "true")
                navigate(0)
            })
            .catch((e) => console.log(e))
    }

    function startTest(quizId: number) {
        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/next?quizId=${quizId}&index=1`)
            .then(response => {
                navigate(`/tests/${quizId}`, { state: tests.current[chosenTest] });
            })
            .catch(error => {
                alert(error.response.data.message)
            });
    }

    const startTestWithSelectedPart = (currentIndex: number, index: number) => {
        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/next?quizId=${index}&index=1`)
            .then(response => {
                // @ts-ignore
                navigate(`/tests/${index}`, { state: tests.current[currentIndex].quizParts[parseInt(selectedPartId)] });
            })
            .catch(error => {
                alert(error.response.data.message)
            });
    };

    return (
        <div style={{ ...Background() }}>
            <NavigationComponent />
            <div style={{ display: 'flex', height: '100%', padding: '125px 10% 125px 10%', flexDirection: 'column' }}>
                <h2 style={{ color: 'white', fontSize: '48px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>Тесты</h2>

                {tests.current.map((test, index) => (
                    <div key={index} id={index.toString()} style={{ ...Test() }} onMouseEnter={handleMouseEnterChapter} onMouseLeave={handleMouseLeaveChapter}>
                        <h3 style={{ color: '#000', fontSize: '34px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', margin: '40px' }}>
                            {test.title}
                        </h3>
                        <div style={{ ...TestContentBlock(index, chosenTest, opacityCondition) }}>
                            <svg style={{ position: 'relative', height: '5px' }} stroke='black' strokeWidth='3'
                                 viewBox="0 0 500 5">
                                <path d='M0 0 L 500 0 '></path>
                            </svg>
                            {test.quizParts ? (
                                <div>
                                    <p>Тема: {test.quizParts[selectedPartId || 0].title}</p>
                                    <p>Количество вопросов: {test.quizParts[selectedPartId || 0].questionCount}</p>
                                    {test.quizParts[selectedPartId || 0].timeInMin && (
                                        <p>Время на выполнение: {test.quizParts[selectedPartId || 0].timeInMin} минут</p>
                                    )}

                                    <p>Выберите часть:</p>
                                    <select onChange={(e) => handleSelectQuizPart(parseInt(e.target.value))}>
                                        <option value="">Выберите часть</option>
                                        {test.quizParts.map((part: QuizPart, partIndex: number) => (
                                            <option key={partIndex} value={partIndex}>
                                                {part.quizPart.description}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ) : (
                                <div>
                                    <p>Тема: {test.title}</p>
                                    <p>Количество вопросов: {test.questionCount}</p>
                                    {test.timeInMin && (
                                        <p>Время на выполнение: {test.timeInMin} минут</p>
                                    )}
                                    <div style={{display: 'flex'}}>
                                        {!test.quizParts && (
                                            <button onClick={() => startTest(test.id)}
                                                    className="start_test_button">Приступить к тесту</button>
                                        )}

                                    </div>
                                </div>
                            )}
                            {test.quizParts && (
                                <div>
                                    <button onClick={() => startTestWithSelectedPart(index, test.quizParts![quizPartsId || 0].id)} className="start_test_button">Приступить к тесту</button>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
            <UserModal
                isOpen={modalUserInfoIsOpen}
                onClose={() => setModalUserInfoIsOpen(false)}
                width="450px">
                <h2 style={{
                    color: '#dddddd',
                    fontSize: '32px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '1',
                    marginTop: '0'
                }}>Вход в систему</h2>
                <div className="search-wrapper">
                    <form>
                        <input
                            type="text"
                            name="focus"
                            required
                            className="search-box"
                            placeholder="Ф.И.О."
                            value={searchQuery}
                            key={selectedUser}
                            onChange={handleSearchQueryChange}
                        />
                        <button className="close-icon" type="reset"></button>
                    </form>
                    {/* Dropdown list with selectable items */}
                    {searchResults.length > 0 && (
                        <div className="dropdown-list">
                            {searchResults.map((result, index) => (
                                <div
                                    key={index}
                                    className="dropdown-item"
                                    onClick={() => handleUserSelect(result)}
                                >
                                    {result.fullName} - {result.platNumber}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className={'button-container'}>
                    <button className={'button -regular center'} onClick={() => submitUser(selectedUser)}>Продолжить</button>
                    <button className={'button -dark center'} onClick={() => setModalUserInfoIsOpen(false)}>Без
                        авторизации
                    </button>
                </div>
            </UserModal>
        </div>
    );
}

export default Tests;