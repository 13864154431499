import { DEFAULT_API_URL } from "../App";
import { createAxiosInstance } from "../utils/apiUtils";

export interface SchemasWithTotalBackend {
    schemas: Array<SchemaBackend>;
    totalElements: number;
}

export interface SchemaBackend {
    id: string;
    img: string;
    name: string;
    shortAbout: string;
}

export async function getSchemas(page: number, size: number, name: string = ""): Promise<SchemasWithTotalBackend> {
    try {
        const response = await createAxiosInstance().get(`${DEFAULT_API_URL}/schemas/search`, {
            params: { page, size, name }
        });
        return {
            schemas: response.data.content,
            totalElements: response.data.totalElements
        };
    } catch (error) {
        throw new Error('Failed to get contents');
    }
}
