import './style.css';
import { DEFAULT_ADMIN_URL } from "../../App";
import React, {useRef, useState} from "react";
import {createAxiosInstance} from "../../utils/apiUtils";
import {useLocation, useNavigate} from "react-router-dom";



interface FormInputs {
    login?: string;
    password?: string;
}

const Login = () => {
    const [inputs, setInputs] = useState<FormInputs>({});
    let navigate = useNavigate();
    let location = useLocation();


    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        sendLoginRequest()
    }

    function sendLoginRequest() {
        createAxiosInstance().post(DEFAULT_ADMIN_URL + "/auth/login", {
            "login": inputs.login,
            "password": inputs.password
        },  {
            withCredentials: true
        })
            .then(response => {
                localStorage.setItem("jwt", response.data)
                navigate(location.state as string || "/")
            })
            .catch(() => alert("Ошибка"))
    }

    return (
        <div className="grid">
            <form onSubmit={handleSubmit} className="form login">
                <header className="login__header">
                    <h3 className="login__title">Авторизация</h3>
                </header>
                <div className="login__body">
                    <div className="form__field">
                        <input type="text" value={inputs.login || ""} onChange={handleChange} name="login" placeholder="Имя пользователя" required />
                    </div>
                    <div className="form__field">
                        <input type="password" value={inputs.password || ""} onChange={handleChange} name="password" placeholder="Пароль" required />
                    </div>
                </div>
                <footer className="login__footer">
                    <input type="submit" value="Войти" />
                    <p><span className="icon icon--info">?</span><a href="#">Забыл пароль</a></p>
                </footer>
            </form>
        </div>
    )
}

export default Login;
