import React, {useState, useEffect, useRef} from "react";
import { NavigationComponent } from "../../components/NavigationComponent";
import { Background, Video, VideoContentBlock } from "./style";
import { DEFAULT_ADMIN_URL, DEFAULT_API_URL } from "../../App";
import {createAxiosInstance, isAdmin} from "../../utils/apiUtils";
import UserModal from "../../components/UserModal";
import { useNavigate } from "react-router-dom";
import './style.css';

// Define the Video type
type Video = {
    id: number;
    title: string;
    fullLink: string;
    startTime: string;
};

const Videos = () => {
    const [videos, setVideos] = useState<Video[]>([]);
    const [chosenVideo, setChosenVideo] = useState(-1);
    const [opacityCondition, setOpacityCondition] = useState(false);
    const isVideoPlaying: boolean[] = [];
    const [modalAddVideoIsOpen, setModalAddVideoIsOpen] = useState(false);
    const [currentAddVideo, setCurrentAddVideo] = useState<Video>({} as Video);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const navigate = useNavigate();



    useEffect(() => {
        const checkAdminStatus = async () => {
            try {
                const adminStatus = await isAdmin();
                setIsAdminUser(adminStatus);
            } catch (error) {
                console.error('Error checking admin status:', error);
            }
        };

        checkAdminStatus();
        // Fetch videos from the API
        createAxiosInstance().get<Video[]>(`${DEFAULT_API_URL}/video`)
            .then(response => {
                // @ts-ignore
                setVideos(response.data.content);
                // @ts-ignore
                console.log(response.data.content)
                // Initialize isVideoPlaying array with false values for each video
                response.data.forEach(() => isVideoPlaying.push(false));
            })
            .catch(error => console.error('Error fetching video data:', error));
    }, []); // Add dependency array to run effect only once

    const handleMouseEnterVideo = (event: React.MouseEvent<HTMLDivElement>) => {
        setChosenVideo(Number(event.currentTarget.id));
        setTimeout(() => { setOpacityCondition(true) }, 200);
    };

    const handleMouseLeaveVideo = () => {
        setChosenVideo(-1);
        setOpacityCondition(false);
    };

    const addVideo = (title: string, fullLink: string, startTime: string) => {
        createAxiosInstance()
            .post(`${DEFAULT_API_URL}/video`, {
                title: title,
                link: fullLink,
                startTime: startTime
            })
            .then(r => {
                setModalAddVideoIsOpen(false);
                navigate(0);
            })
            .catch(e => console.error('Error updating video:', e));
    };

    const deleteVideo = (videoId: Number) => {
        createAxiosInstance()
            .delete(`${DEFAULT_API_URL}/video?index=${videoId}`)
            .then(() => {
                navigate(0)
            })
            .catch(e => console.error('Error deleting video:', e));
    }

    return (
        <div style={{ ...Background() }}>
            <NavigationComponent />
            <div style={{ display: 'flex', height: '100%', padding: '125px 10% 125px 10%', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', alignItems: 'center' }}>
                    <h2 style={{ color: 'white', fontSize: '48px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal' }}>Видео</h2>
                    { isAdminUser &&
                        <span style={{
                            color: 'white',
                            fontSize: '32px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal'
                        }} className="material-symbols-outlined"
                              onClick={() => setModalAddVideoIsOpen(true)}>add_box</span>

                    }
                </div>
                {videos.map((video, index) => (
                    <div
                        key={video.id}
                        id={video.id.toString()}
                        onMouseEnter={handleMouseEnterVideo}
                        onMouseLeave={handleMouseLeaveVideo}
                        style={{ ...Video(video.id, chosenVideo) }}
                    >
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'row',
                            width: '100%'
                            }}>

                            <h3 style={{
                                color: '#000',
                                fontSize: '24px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                                margin: '44px'
                            }}>
                                {video.title}
                            </h3>
                            {isAdminUser &&
                                <span style={{
                                    color: 'black',
                                    fontSize: '32px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    marginRight: '44px'
                                }} className="material-symbols-outlined"
                                      onClick={() => deleteVideo(video.id)}>delete</span>
                            }
                        </div>
                        <div>
                            <div style={{...VideoContentBlock(video.id, video.id, true, isVideoPlaying[video.id])}}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src={video.fullLink}
                                    title={video.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    </div>
                ))}

                <UserModal isOpen={modalAddVideoIsOpen} onClose={() => { setModalAddVideoIsOpen(false) }} width={'30%'}>
                    <h2 className="modal-title">Добавить видео</h2>
                    <div className="modal-content">
                        <input
                            type="text"
                            name="title"
                            required
                            className="input-field"
                            placeholder="Название видео"
                            value={currentAddVideo?.title}
                            onChange={(e) => setCurrentAddVideo({
                                ...currentAddVideo,
                                title: e.target.value
                            })}
                        />

                        <input
                            type="text"
                            name="fullLink"
                            required
                            className="input-field"
                            placeholder="Ссылка на видео"
                            value={currentAddVideo?.fullLink}
                            onChange={(e) => setCurrentAddVideo({
                                ...currentAddVideo,
                                fullLink: e.target.value
                            })}
                        />

                        <input
                            type="text"
                            name="startTime"
                            required
                            className="input-field"
                            placeholder="Время начала видео (hh:mm:ss)"
                            value={currentAddVideo?.startTime}
                            onChange={(e) => setCurrentAddVideo({
                                ...currentAddVideo,
                                startTime: e.target.value
                            })}
                        />
                    </div>
                    <button className="save-button"
                            onClick={() => currentAddVideo && addVideo(currentAddVideo?.title, currentAddVideo?.fullLink, currentAddVideo?.startTime)}>
                        Сохранить
                    </button>
                </UserModal>
            </div>
        </div>
    );
};

export default Videos;
