import React, { useState, useEffect } from 'react';
import { NavigationComponent } from '../../components/NavigationComponent';
import { Background } from './style';
import { getSchemas, SchemaBackend, SchemasWithTotalBackend } from "../../backend/schemas";
import { SchemesGenerator } from "../../components/SchemaGenerator/schemasGenerator";
import { handleInputChange } from "../../components/SearchGenerator/common";
import { Pagination } from "../../components/Pagination/pagination";

const Schemas: React.FC = () => {
    const [chousenChapter, setChousenChapter] = useState(-1);
    const [opacityCondition, setOpacityCondition] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [schemasWithTotal, setSchemasWithTotal] = useState<SchemasWithTotalBackend | null>(null);

    useEffect(() => {
        const fetchSchemas = async () => {
            try {
                const data = await getSchemas(page, rowsPerPage, searchTerm);
                setSchemasWithTotal(data);
            } catch (error) {
                console.error('Failed to fetch schemas:', error);
            }
        };

        fetchSchemas();
    }, [page, rowsPerPage, searchTerm]);

    const handleMouseEnterChapter = (event: React.MouseEvent<HTMLDivElement>) => {
        setChousenChapter(Number(event.currentTarget.id));
        setTimeout(() => { setOpacityCondition(true); }, 300);
    };

    const handleMouseLeaveChapter = (event: React.MouseEvent<HTMLDivElement>) => {
        setChousenChapter(-1);
        setOpacityCondition(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!schemasWithTotal) return <div>Loading...</div>;

    return (
        <div style={{ ...Background() }}>
            <div style={{ height: '100%' }}>
                <NavigationComponent />
                <div style={{ display: 'flex', height: '100%', padding: '125px 10%', flexDirection: 'column' }}>
                    <h2 style={{ color: 'white', fontSize: '48px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>Содержание</h2>
                    <input
                        onChange={(event) => setSearchTerm(handleInputChange(event))}
                        style={{
                            color: 'black',
                            backgroundColor: '#D2AE84',
                            borderRadius: '30px',
                            height: '50px',
                            textIndent: '20px',
                            fontSize: '20px'
                        }}
                    />
                    <Pagination
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalElements={schemasWithTotal.totalElements}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <SchemesGenerator
                        opacityCondition={opacityCondition}
                        chousenChapter={chousenChapter}
                        handleMouseEnterChapter={handleMouseEnterChapter}
                        handleMouseLeaveChapter={handleMouseLeaveChapter}
                        chapters={schemasWithTotal.schemas}
                    />
                </div>
            </div>
        </div>
    );
};

export default Schemas;
