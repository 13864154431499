import { NavigationComponent } from '../../components/NavigationComponent';
import { Background } from './style';
import './style.css';
import {useEffect, useState} from "react";
import {createAxiosInstance} from "../../utils/apiUtils";
import {DEFAULT_API_URL} from "../../App";
import {useLocation} from "react-router-dom";

const ContentsInner = () => {
    const location = useLocation();
    const [articleData, setArticleData] = useState<string>("");
    const path = location.pathname.split('/').pop();

    function parseHtml(data: string) {
        let tempData = data;
        let tempImageSrcs: string[] = [];
        let regex = /<img.*?src="(.*?)"/g;
        let match;

        while ((match = regex.exec(tempData)) !== null) {
            tempImageSrcs.push(match[1]);
        }

        tempImageSrcs.forEach((src) => {
            tempData = tempData.replace(src, DEFAULT_API_URL +"/resource/img/" + src);
        });

        tempData = tempData.replace(/<img(.*?)style="(.*?)"/g, '<img$1style="$2;padding: 8px;border-radius: 10px;background-color: white;"');

        setArticleData(tempData);
    }


    useEffect(() => {
        createAxiosInstance().get(DEFAULT_API_URL + `/article?id=${path}`)
            .then(response => {
                parseHtml(response.data)
            })
            .catch(error => {
                console.error("Error fetching article data:", error);
            });

        (window as any).nextGif = (event?: MouseEvent) => {
            if (event) {
                var container = (event.target as Element).closest('.gif-container');

                if (container) {
                    var slides = container.querySelectorAll('.gif-slide');
                    var visibleSlide = container.querySelector('.gif-slide:not([style*="display: none"])') as HTMLElement;

                    if (visibleSlide) {
                        visibleSlide.style.display = 'none';
                        var nextIndex = (parseInt(visibleSlide.id.split('-')[2]) % slides.length) + 1;
                        var nextSlide = container.querySelector('#gif-slide-' + nextIndex) as HTMLElement;
                        if (nextSlide) {
                            nextSlide.style.display = 'block';
                        }
                    }
                }
            }
        }

        (window as any).prevGif = (event?: MouseEvent) => {
            if (event) {
                var container = (event.target as Element).closest('.gif-container');

                if (container) {
                    var slides = container.querySelectorAll('.gif-slide');
                    var visibleSlide = container.querySelector('.gif-slide:not([style*="display: none"])') as HTMLElement;

                    if (visibleSlide) {
                        visibleSlide.style.display = 'none';
                        var prevIndex = (parseInt(visibleSlide.id.split('-')[2]) - 2 + slides.length) % slides.length + 1;
                        var prevSlide = container.querySelector('#gif-slide-' + prevIndex) as HTMLElement;
                        if (prevSlide) {
                            prevSlide.style.display = 'block';
                        }
                    }
                }
            }
        }

        // Clean up the global function
        return () => {
            delete (window as any).nextGif;
            delete (window as any).prevGif;
        };
    }, [path]);




    return(
        <div  style={{...Background()}}>
            <div style={{height:'100%'}}>
                <NavigationComponent></NavigationComponent>
                <div className={"contents-inner-page"}>
                    <div className={"content-title"}>
                        <p style={{fontSize: "48px", marginBottom: "16px", fontWeight: 800}}>{location.state.chapter}</p>
                        <p style={{fontSize: "36px", marginBottom: "16px", marginTop: "16px", fontWeight: 600}}>{location.state.title}</p>
                        <p style={{fontSize: "24px", marginTop: "16px", fontWeight: 600}}>{location.state.subtitle}</p>
                        <hr/>
                    </div>
                    { <div className={"content-body"} dangerouslySetInnerHTML={{ __html: articleData }}/> }
                </div>
            </div>
        </div>
    )
}

export default ContentsInner