import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationComponent } from "../../../components/NavigationComponent";
import { Background } from "./style";
import './glossaryPage.css';
import { getGlossary, GlossaryBackend } from "../../../backend/glossary";
import { DEFAULT_API_URL } from "../../../App";
import axios from "axios";

export const GlossaryPage: React.FC = () => {
    const { pageId } = useParams<{ pageId: string }>();
    const [glossary, setGlossary] = useState<GlossaryBackend | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchGlossary = async (id: string) => {
            try {
                const data = await getGlossary(id);
                setGlossary(data);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    setError(error.response?.data.message || 'Failed to fetch glossary');
                } else {
                    setError("An unexpected error occurred");
                }
            } finally {
                setLoading(false);
            }
        };

        if (pageId) {
            fetchGlossary(pageId);
        }
    }, [pageId]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;

    return (
        <div style={{ ...Background() }}>
            <div style={{ height: '100%' }}>
                <NavigationComponent />
                <div className="glossary-page">
                    <div style={{
                        height: '100%',
                        padding: '125px 10%',
                    }}>
                        {glossary && (
                            <>
                                <h2>{glossary.name}</h2>
                                <img
                                    style={{ height: '400px', maxWidth: '100%' }}
                                    src={`${DEFAULT_API_URL}/glossary/image/${pageId}`}
                                    alt={glossary.name}
                                />
                                <h3>Коротко:</h3>
                                <p>{glossary.shortAbout}</p>
                                <h3>Полное описание:</h3>
                                <div className="content" dangerouslySetInnerHTML={{ __html: glossary.fullAbout }}></div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
