import React, {useState, useEffect, useRef} from 'react';
import IMAGE_GUEST from './guest.png';
import "./style.css";
import { createAxiosInstance } from "../../utils/apiUtils";
import {DEFAULT_ADMIN_URL, DEFAULT_API_URL} from "../../App";
import { NavigationComponent } from '../../components/NavigationComponent';
import { Link } from 'react-router-dom';
import {useLocation, useNavigate} from "react-router-dom";
import UserModal from "../../components/UserModal";
import 'material-symbols'


interface Student {
    login: string;
    fullName: string;
    platNumber: string;
    firstName: string;
    lastName: string;
    middleName: string;
}

const AdminPanel: React.FC = () => {
    const [students, setStudents] = useState<Student[]>([]);
    const [pageInfo, setPageInfo] = useState<{ currentPage: number; totalPages: number }>({ currentPage: 0, totalPages: 0 });
    const [filters, setFilters] = useState<{ fullName: string; platNumber: string }>({ fullName: '', platNumber: '' });
    const [searchFullName, setSearchFullName] = useState<string>('');
    const [platNumbers, setPlatNumbers] = useState<string[]>([]);
    const [selectedPlatNumber, setSelectedPlatNumber] = useState<string>('');
    const navigate = useNavigate();
    const location = useLocation();
    const [userData, setUserData] = useState<Student>({
        firstName: "",
        fullName: "",
        lastName: "",
        login: "",
        middleName: "",
        platNumber: ""
    });
    const [modalEditUserIsOpen, setModalEditUserIsOpen] = useState(false);
    const [currentEditStudent, setCurrentEditStudent] = useState<Student>({} as Student);
    const [isChecked, setIsChecked] = useState(false);


    const handleToggle = (event: { target: { checked: boolean }; }) => {
        setIsChecked(event.target.checked);
        switchTestLogin(event.target.checked);
    };

    useEffect(() => {
        createAxiosInstance().get(DEFAULT_API_URL + "/session")
            .then((response) => {
                if(response.data.userInfo == null || response.data.userInfo.role !== "ROLE_ADMIN") {
                    navigate("/login", {state: location.pathname})
                }
                setUserData(response.data.userInfo)
                setIsChecked(response.data.testEnabled)
            })
            .catch((error) => {
                console.log("Error fetching session data:", error);
            })
        fetchData();
        getPlatNumbers();
    }, [pageInfo.currentPage, filters]);

    const fetchData = () => {
        createAxiosInstance()
            .get(`${DEFAULT_ADMIN_URL}/students?page=${pageInfo.currentPage}&size=5`, { params: { ...filters, platNumber: selectedPlatNumber } })
            .then(response => {
                setStudents(response.data.content);
                setPageInfo({ currentPage: response.data.number, totalPages: response.data.totalPages });
            })
            .catch(error => console.error('Error fetching students:', error));
    };

    const handleApplyFilters = () => {
        setFilters({ fullName: searchFullName, platNumber: selectedPlatNumber });
        fetchData();
    };

    const getPlatNumbers = () => {
        createAxiosInstance().get(`${DEFAULT_ADMIN_URL}/students/plats`)
            .then(response => {
                setPlatNumbers(response.data.content);
            })
            .catch(error => console.error('Error fetching plat numbers:', error));
    };

    const handleUploadStudents = () => {
        //open file explorer. When file selected, send to the api
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = '.xlsx' || '.xlx';
        fileInput.onchange = (e) => {
            const file = (e.target as HTMLInputElement).files?.[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                createAxiosInstance().post(`${DEFAULT_ADMIN_URL}/students/upload`, formData)
                    .then(r => fetchData())
                    .catch(e => console.error('Error uploading students:', e));
            }
        };
        fileInput.click();
    };

    const handlePageChange = (newPage: number) => {
        setPageInfo({ ...pageInfo, currentPage: newPage });
    };

    const deleteStudent = (login: string) => {
        createAxiosInstance().delete(`${DEFAULT_ADMIN_URL}/students/delete?login=${login}`)
            .then(r => fetchData())
            .catch(e => console.error('Error deleting student:', e));
    };

    const updateStudent = (login: string, firstName: string, lastName: string, middleName: string, platNumber: string,) => {
        createAxiosInstance()
            .patch(`${DEFAULT_ADMIN_URL}/students/update`, {
                login: login,
                firstName: firstName === undefined ? '' : firstName,
                lastName: lastName === undefined ? '' : lastName,
                middleName: middleName === undefined ? '' : middleName,
                platNumber: platNumber === undefined ? '' : platNumber
            })
            .then(r => {
                fetchData();
                setModalEditUserIsOpen(false);
            } )
            .catch(e => console.error('Error updating student:', e))

    };

    const openEditModal = (login: string) => {
        createAxiosInstance().get(`${DEFAULT_ADMIN_URL}/students?login=${login}`)
            .then(response => {
                const studentData = response.data.content[0]; // Assuming student data is directly in response.data.content[0]
                setCurrentEditStudent({
                    login: studentData.login,
                    fullName: studentData.fullName,
                    firstName: studentData.firstName,
                    lastName: studentData.lastName,
                    middleName: studentData.middleName,
                    platNumber: studentData.platNumber
                });
                setModalEditUserIsOpen(true);
            })
            .catch(error => console.error('Error fetching student:', error));
    }

    const switchTestLogin = (state: boolean) => {
        createAxiosInstance()
            .patch(`${DEFAULT_API_URL}/session/quiz?enable=${state}`)
            .then(response => {
                localStorage.setItem("testLoginEnabled", String(state))
            })
        .catch(e => console.error('Error switching test login: ', e))
    }

    return (
        <div>
            <NavigationComponent></NavigationComponent>
            <div className="admin-panel">
                <div className="profile-section">
                    <div style={{display:"flex", alignItems: "center"}}>
                        <img src={IMAGE_GUEST} alt="Profile" />
                        <div>
                            <div className="name">{userData.lastName} {userData.firstName} {userData.middleName}</div>
                        </div>
                    </div>
                    <div>
                        <ul className="tg-list">
                            <li className="tg-list-item">
                                <h3 style={{color: "#ffffff"}}>Авторизация студентов</h3>
                                <input className="tgl tgl-light"
                                       id="cb1" type="checkbox"
                                       checked={isChecked}
                                       onChange={handleToggle}/>
                                <label className="tgl-btn" htmlFor="cb1"></label>

                            </li>
                        </ul>
                    </div>
                </div>

                <div className="filters">
                    <input
                        type="text"
                        placeholder="ФИО"
                        value={searchFullName}
                        onChange={(e) => setSearchFullName(e.target.value)}
                    />
                    <select
                        value={selectedPlatNumber}
                        onChange={(e) => setSelectedPlatNumber(e.target.value)}
                    >
                        <option value="">Выберите номер взвода</option>
                        {platNumbers.map((platNumber: string) => (
                            <option key={platNumber} value={platNumber}>{platNumber}</option>
                        ))}
                    </select>
                    <button onClick={handleApplyFilters}>Применить</button>
                    <button onClick={handleUploadStudents}>Загрузить студентов</button>
                </div>
                <table className='rounded-corners'>
                    <thead>
                    <tr>
                        <th>ФИО</th>
                        <th>Номер Взвода</th>
                        <th>Действия</th>
                    </tr>
                    </thead>
                    <tbody>
                    {students.map((student) => (
                        <tr key={student.login}>
                            <td>{student.fullName}</td>
                            <td>{student.platNumber}</td>
                            <td>
                                <span key={`edit-${student.login}`} className="material-symbols-outlined edit" onClick={() => openEditModal(student.login)}>edit</span>
                                <span key={`delete-${student.login}`} className="material-symbols-outlined delete" onClick={() => deleteStudent(student.login)}>delete</span>
                                <Link to={"/cabinet/studentResults"} state={{studentFullName:student.fullName, studentLogin:student.login }}>
                                    <span key={`see-${student.login}`} className="material-symbols-outlined">quick_reference_all</span>
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={() => handlePageChange(pageInfo.currentPage - 1)} disabled={pageInfo.currentPage === 0}>
                        Назад
                    </button>
                    <span className='pageSpan'>{`Страница ${pageInfo.currentPage + 1} из ${pageInfo.totalPages}`}</span>
                    <button onClick={() => handlePageChange(pageInfo.currentPage + 1)} disabled={pageInfo.currentPage === pageInfo.totalPages - 1}>
                        Вперед
                    </button>
                </div>
                <UserModal isOpen={modalEditUserIsOpen} onClose={() => setModalEditUserIsOpen(false)} width={"30%"}>
                    <h2 className="modal-title">Редактирование студента</h2>
                    <div className="modal-content">
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name="lastName"
                                required
                                className="input-field"
                                placeholder="Фамилия"
                                value={currentEditStudent?.lastName}
                                onChange={(e) => setCurrentEditStudent({ ...currentEditStudent, lastName: e.target.value })}
                            />
                            <input
                                type="text"
                                name="firstName"
                                required
                                className="input-field"
                                placeholder="Имя"
                                value={currentEditStudent?.firstName}
                                onChange={(e) => setCurrentEditStudent({ ...currentEditStudent, firstName: e.target.value })}
                            />
                        </div>
                        <div className="input-wrapper">
                            <input
                                type="text"
                                name="middleName"
                                required
                                className="input-field"
                                placeholder="Отчество"
                                value={currentEditStudent?.middleName}
                                onChange={(e) => setCurrentEditStudent({ ...currentEditStudent, middleName: e.target.value })}
                            />
                            <input
                                type="text"
                                name="platNumber"
                                required
                                className="input-field"
                                placeholder="Номер взвода"
                                value={currentEditStudent?.platNumber}
                                onChange={(e) => setCurrentEditStudent({ ...currentEditStudent, platNumber: e.target.value })}
                            />
                        </div>
                    </div>
                    <button className="save-button" onClick={() => currentEditStudent && updateStudent(currentEditStudent?.login, currentEditStudent?.firstName, currentEditStudent?.lastName, currentEditStudent?.middleName, currentEditStudent?.platNumber)}>
                        Сохранить
                    </button>
                </UserModal>


            </div>
        </div>
    );
};

export default AdminPanel;