export function Background() {
    return({
        backgroundColor: '#00150B',
        fontFamily: 'Fortuna',
        height: '100%',
        width: '100%'
    })
}

export function Test() {
    return({
        width: '100%',
        backgroundColor:'#D2AE84', 
        borderRadius:'30px', 
        display:'flex', 
        alignItems:'flex-start',
        flexDirection:'column',
        marginTop:'10px'
    } as const)
}

export function TestContentBlock(chapterId:number, chosenTest:number, opacityCondition:boolean) {
    return({
        transition: '.9s cubic-bezier(.3, 0, 0, 1.3)',
        overflow: 'hidden',
        maxHeight: chosenTest === chapterId ? '1000px':'0',
        padding: chosenTest === chapterId ? '0 44px 44px  44px' : '0',
        opacity: chosenTest === chapterId && opacityCondition ? '1' : '0'
    } as const)
}

export function submitButtonModal() {
    return({
        background: "#000000"
    })
}