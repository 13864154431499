import React from 'react';
import { useState, useEffect } from 'react';
import UserModal from "../components/UserModal";

interface TimerProps {
    deadline: Date;
    testId: string;
}

export const Timer = (props:TimerProps) => {
    const [modalEditUserIsOpen, setModalEditUserIsOpen] = useState(false);
    const [minutes, setMinutes] = useState(new Date(props.deadline.getTime() - Date.now()).getMinutes());
    const [seconds, setSeconds] = useState(new Date(props.deadline.getTime() - Date.now()).getSeconds());

    const getTime = () => {
        const time = props.deadline.getTime() - Date.now();

        if (time <= 0){
            setMinutes(0)
            setSeconds(0)
            setModalEditUserIsOpen(true)
            return
        }

        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(getTime, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <p style={{display: "inline"}}>{minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>
            <UserModal isOpen={modalEditUserIsOpen} onClose={() => setModalEditUserIsOpen(false)} width={"50%"}>
                <p>Время вышло!</p>
                <button onClick={() => {window.location.href = `/tests/${props.testId}/result`}}>Завершить тест</button>
            </UserModal>
        </>
    );
}