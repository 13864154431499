import { DEFAULT_API_URL } from "../App";
import { createAxiosInstance } from "../utils/apiUtils";

export interface GlossaryWithTotalBackend {
    glossary: Array<GlossaryBackend>;
    totalElements: number;
}

export interface GlossaryBackend {
    id: string;
    group: string;
    name: string;
    shortAbout: string;
    fullAbout: string;
}

export async function getGlossaries(page: number, size: number, name: string = "", group: string = ""): Promise<GlossaryWithTotalBackend> {
    try {
        const response = await createAxiosInstance().get(`${DEFAULT_API_URL}/glossary/search`, {
            params: { page, size, name, group }
        });
        return {
            glossary: response.data.content,
            totalElements: response.data.totalElements
        };
    } catch (error) {
        throw new Error('Failed to get glossary');
    }
}

export async function getGlossary(id: string): Promise<GlossaryBackend> {
    try {
        const response = await createAxiosInstance().get(`${DEFAULT_API_URL}/glossary/${id}`);
        return response.data;
    } catch (error) {
        throw new Error('Failed to get glossary');
    }
}
