import React, { useEffect, useState } from "react";
import './style.css';
import { NavigationComponent } from "../../components/NavigationComponent";
import { DEFAULT_ADMIN_URL } from "../../App";
import { createAxiosInstance } from "../../utils/apiUtils";
import { useLocation } from "react-router-dom";
import UserModal from "../../components/UserModal";

interface Results {
    id: number;
    title: string;
    quizGroup: quizGroup;
    quizPart: quizPart;
    questionCount: number;
    correctCount: number;
    mark: number;
    timeSpendInMills: number;
    date: string;
}

interface quizGroup {
    name: string;
    description: string
}

interface quizPart {
    name: string;
    description: string
}

interface Answers {
    question: string,
    answerOptions: string[]
    rightAnswers: number[]
    userAnswers: number[]
    answerType: string
    correct: number
}

const StudenQuizResults = () => {
    const [resultPageInfo, setResultPageInfo] = useState<{ currentPage: number; totalPages: number }>({
        currentPage: 0,
        totalPages: 0
    });
    const [answersPageInfo, setAnswersPageInfo] = useState<{ currentPage: number; totalPages: number }>({
        currentPage: 0,
        totalPages: 0
    });
    const [results, setResults] = useState<Results[]>([]);
    const [modalAnswerInfoIsOpen, setModalAnswerInfoIsOpen] = useState(false);
    const [answers, setAnswers] = useState<Answers[]>([]);
    const [selectedResultId, setSelectedResultId] = useState<number | null>(null);
    const location = useLocation();
    let name = location.state.studentFullName;

    useEffect(() => {
        fetchData(resultPageInfo.currentPage);
    }, [resultPageInfo.currentPage]);

    const fetchData = (page: number) => {
        createAxiosInstance()
            .get(`${DEFAULT_ADMIN_URL}/quiz/results?login=${location.state.studentLogin}&page=${page}&size=20`)
            .then(response => {
                setResults(response.data.content);
                setResultPageInfo({ currentPage: response.data.number, totalPages: response.data.totalPages });
            })
            .catch(error => console.error('Error fetching students:', error));
    };

    const answerFetch = (resultId: number, currentPage: number) => {
        setModalAnswerInfoIsOpen(true);
        setSelectedResultId(resultId);
        createAxiosInstance()
            .get(`${DEFAULT_ADMIN_URL}/quiz/results/answers?id=${resultId}&page=${currentPage}&size=5`)
            .then(response => {
                setAnswers(response.data.content);
                setAnswersPageInfo({ currentPage: response.data.number , totalPages: response.data.totalPages });
            })
            .catch(error => console.error('Error fetching answers:', error));
    };


    const handleResultPageChange = (newPage: number) => {
        setResultPageInfo({ ...resultPageInfo, currentPage: newPage });
    };

    const handleAnswerPageChange = (newPage: number) => {
        setAnswersPageInfo({ ...answersPageInfo, currentPage: newPage }); // Update currentPage state first
        if (selectedResultId !== null) {
            answerFetch(selectedResultId, newPage); // Pass the newPage to answerFetch function
        }
    }

    const handleResultClick = (result: Results) => {
        setSelectedResultId(result.id);
        answerFetch(result.id, answersPageInfo.currentPage); // Pass both result.id and currentPage
    };

    function getResIcon(userAnswers: number[], rightAnswers:number[]){
        if (userAnswers == null){
            return "❓"
        }

        if (JSON.stringify(userAnswers) == JSON.stringify(rightAnswers)){
            return "✅"
        }

        userAnswers.map(answer => {
            if (rightAnswers.indexOf(answer) != -1){
                return "🟡"
            }
        })

        return "❌"
    }

    function getTime(timeSpendInMills:number):string{
        const minutes = Math.floor((timeSpendInMills / 1000 / 60) % 60)
        const seconds = Math.floor((timeSpendInMills / 1000) % 60)

        if (minutes == 0 && seconds == 0){
            return "неизвестно"
        }

        return (minutes < 10 ? "0" + minutes.toString() : minutes.toString() ) + ":" + (seconds < 10 ? "0" + seconds.toString()  : seconds.toString() )
    }

    return (
        <div>
            <NavigationComponent></NavigationComponent>
            <div style={{ padding: "110px 7%" }}>
                <h2 style={{ color: "#ffffffd4" }}>{name}</h2>
                <table className='rounded-corners'>
                    <thead>
                    <tr>
                        <th>Наименование теста</th>
                        <th>Часть теста</th>
                        <th>Время прохождения теста</th>
                        <th>Дата прохождения теста</th>
                        <th>Кол-во вопросов</th>
                        <th>Правильных ответов</th>
                        <th>Оценка</th>
                    </tr>
                    </thead>
                    <tbody>
                    {results.map((result) => (
                        <tr key={result.id} onClick={() => handleResultClick(result)} style={{ cursor: "pointer" }}>
                            <td>{result.title}</td>
                            <td>{result.quizPart == null ? ("-") : (result.quizPart.description)}</td>
                            <td>{getTime(result.timeSpendInMills)}</td>
                            <td>{result.date}</td>
                            <td>{result.questionCount}</td>
                            <td>{result.correctCount}</td>
                            <td>{result.mark}</td>
                        </tr>
                    ))}
                    </tbody>
                    <div className="pagination">
                        <button onClick={() => handleResultPageChange(resultPageInfo.currentPage - 1)}
                                disabled={resultPageInfo.currentPage === 0}>
                            Назад
                        </button>
                        <span className='pageSpan'>{`Страница ${resultPageInfo.currentPage + 1} из ${resultPageInfo.totalPages}`}</span>
                        <button onClick={() => handleResultPageChange(resultPageInfo.currentPage + 1)}
                                disabled={resultPageInfo.currentPage === resultPageInfo.totalPages - 1}>
                            Вперед
                        </button>
                    </div>
                </table>
                <UserModal
                    isOpen={modalAnswerInfoIsOpen}
                    onClose={() => setModalAnswerInfoIsOpen(false)}
                    width="90%">
                    <h2 style={{
                        color: '#dddddd',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '1',
                        marginTop: '0'
                    }}>Ответы данные студентом</h2>
                    <table className='rounded-corners'>
                        <thead>
                        <tr>
                            <th>Вопрос</th>
                            <th>Варианты ответов</th>
                            <th>Ответ студента</th>
                            <th>Правильный ответ</th>
                            <th>Правильность</th>
                        </tr>
                        </thead>
                        <tbody>
                        {answers.map((answer) => (
                            <tr key={answer.question}>
                                <td style={{fontSize: "19px"}}>{answer.question}</td>
                                <td style={{fontSize: "19px"}}>{answer.answerOptions.map((option, i) => (
                                    <p key={i}>{i + 1}. {option}</p>
                                ))}</td>
                                <td>{
                                    (answer.userAnswers || []).map((ans, i) => (
                                    <p key={i}>{i + 1}. {ans}</p>))
                                }</td>
                                <td>{answer.rightAnswers.map((ans, i) => (
                                    <p key={i}>{i + 1}. {ans}</p>))}</td>
                                <td style={{fontSize: "19px", textAlign: "center"}}>{getResIcon(answer.userAnswers, answer.rightAnswers)}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={() => handleAnswerPageChange(answersPageInfo.currentPage - 1)}
                                disabled={answersPageInfo.currentPage === 0}>
                            Назад
                        </button>
                        <span className='pageSpan'>{`Страница ${answersPageInfo.currentPage + 1} из ${answersPageInfo.totalPages}`}</span>
                        <button onClick={() => handleAnswerPageChange(answersPageInfo.currentPage + 1)}
                                disabled={answersPageInfo.currentPage === answersPageInfo.totalPages - 1}>
                            Вперед
                        </button>
                    </div>
                </UserModal>
            </div>
        </div>
    );
}

export default StudenQuizResults;
