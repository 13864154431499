import React, { useEffect, useState } from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import "./style.css";
import {createAxiosInstance} from "../../utils/apiUtils";
import {DEFAULT_API_URL} from "../../App";

const QuizResult = () => {
    const location = useLocation();
    let { quizId } = useParams()
    const [resultData, setResultData] = useState({
        correctAnswers: 0,
        totalAnswers: 0,
        mark: 0,
        timeSpendInMills: 0
    });

    useEffect(() => {
        if (resultData.timeSpendInMills != 0) {
            return
        }

        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/result?quizId=${quizId}`)
            .then(response => {
                setResultData(response.data)
            }).catch(error => {
                console.error("Error fetching quiz result:", error);
            })
    }, [resultData]);

    const minutes = Math.floor((resultData.timeSpendInMills / 1000 / 60) % 60)
    const seconds = Math.floor((resultData.timeSpendInMills / 1000) % 60)

    return (
        <div className="outer-quiz-container">
            <div className="quiz-result-container" style={{color:"#ffffffd4"}}>
                <p >Правильных ответов: {resultData.correctAnswers}</p>
                <p>Всего вопросов: {resultData.totalAnswers}</p>
                <p>Затраченное время: {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>
                <p>Оценка: {resultData.mark}</p>
                
                <Link to={`/tests`}>
                    <button className="submit-button">Вернуться на страницу тестов</button>
                </Link>
            </div>
        </div>
    );
};

export default QuizResult;
