import React, { EventHandler } from "react";
import {ReactComponent as IconClose} from "./icon-close.svg";
import './style.css';
import {Transition} from 'react-transition-group';

interface UserModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    width: string
}

const UserModal: React.FC<UserModalProps> = ({ isOpen, onClose, children, width }) => {

    return (
        <>
            <Transition in={isOpen} timeout={350} unmountOnExit={true}>
                {(state) => (
                  <div className={`modal modal--${state}`}>
                      <div className='modal-wrapper'>
                          <div className='modal-content' id='modal-content-id' style={{maxWidth:width}}>
                              <button className='modal-close-button' style={{cursor:'pointer'}}
                                      onClick={() => onClose()}>
                                  <IconClose/>
                              </button>
                              {children}
                          </div>
                      </div>
                  </div>
                )}
            </Transition>
        </>
    );

};

export default UserModal;