import axios, { AxiosInstance } from 'axios';
import {DEFAULT_API_URL} from "../App";

// Function to check if JWT token exists in local storage
export const getToken = (): string | null => {
    return localStorage.getItem('jwt');
};

// Function to handle Axios errors
export const handleRequestError = (error: any): Promise<never> => {
    // Handle error
    console.error('Request Error:', error);
    return Promise.reject(error);
};

// Function to create Axios instance with default configurations
export const createAxiosInstance = (): AxiosInstance => {
    const instance = axios.create();

    // Request interceptor to set Authorization header
    instance.interceptors.request.use(
        config => {
            const token = getToken();

            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            config.headers['Allow-Control-Allow-Origin'] = '*';

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // Response interceptor to handle errors
    instance.interceptors.response.use(
        response => {
            return response;
        },
        error => {
            return handleRequestError(error);
        }
    );
    instance.defaults.withCredentials = true;

    return instance;
};

export const isAdmin = async (): Promise<boolean> => {
    return await createAxiosInstance().get(`${DEFAULT_API_URL}/session`)
        .then(response => {
            return response.data.userInfo.role === 'ROLE_ADMIN';
        })
}
