import { createBrowserRouter } from "react-router-dom";
import Landing from "../pages/Landing";
import Schemas from "../pages/Schemas";
import Glossary from "../pages/Glossary";
import Tests from "../pages/Tests";
import Videos from "../pages/Videos";
import {GlossaryPage} from "../pages/Glossary/GlossaryPage/glossaryPage";
import Login from "../pages/Login";
import QuizPage from "../pages/QuizPage";
import QuizResult from "../pages/QuizResult";
import AdminPanel from "../pages/AdminPanel";
import Contents from "../pages/Contents";
import StudenQuizResults from "../pages/StudentQuizResults";
import ContentsInner from "../pages/ContentsInner";


export const router = createBrowserRouter([
  {
    path: "/",
    element: <Landing />,
  },
  {
    path: "/contents",
    element: <Contents/>
  },
  {
    path: "/contents/:id",
    element: <ContentsInner/>
  },
  {
    path: "/glossary",
    element: <Glossary/>
  },
  {
    path: "/glossary/:pageId",
    element: <GlossaryPage/>
  },
  {
    path: "/tests",
    element: <Tests/>
  },
  {
    path: "/tests/:quizId",
    element: <QuizPage/>
  },
  {
    path: "/tests/:quizId/result",
    element: <QuizResult/>
  },
  {
    path: "/videos",
    element: <Videos/>
  },
  {
    path: "/schemas",
    element: <Schemas/>
  },
  {
    path: "/login",
    element: <Login/>
  },
  {
    path: "/cabinet",
    element: <AdminPanel/>
  },
  {
    path: "/cabinet/studentResults",
    element: <StudenQuizResults/>
  }
]);