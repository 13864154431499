export function Background() {
    return({
        backgroundColor: '#00150B',
        fontFamily: 'Fortuna',
        minHeight: '100vh',
        maxWidth: '100vw'
    })
}




