import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./style.css";
import {DEFAULT_API_URL} from "../../App";
import {createAxiosInstance} from "../../utils/apiUtils";
import {Timer} from "../../modules/Timer";

interface QuizData {
    question: string;
    answerOptions: string[];
    answerType: "SINGLE" | "MULTI";
    userAnswers: Array<number>;
    timeToEndInMills: number;
}

interface DescriptionName {
    description: string,
    name: string
}

interface QuizInfo {
    id: number,
    preResult: boolean,
    questionCount: number,
    quizGroup: DescriptionName,
    quizPart: DescriptionName,
    timeInMin: number,
    title: string,
}

const BackArrowIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffffd4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-arrow-left"
        style={{position:"initial", cursor:"pointer"}}
    >
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
    </svg>
);

const QuizPage = () => {
    const index = useRef(1);
    const navigate = useNavigate();
    const [quizData, setQuizData] = useState<QuizData | null>(null);
    const [quizInfo, setQuizInfo] = useState<QuizInfo>({
        id: 0,
        title: "",
        preResult: false,
        quizGroup: {
            description: "",
            name: "",
        },
        timeInMin: 0,
        questionCount: 0,
        quizPart: {
            description: "",
            name: "",
        }
    });
    const [userAnswers, setUserAnswers] = useState<Array<boolean>>([]);
    const location = useLocation();
    const quizId = location.pathname.split("/").pop();

    useEffect(() => {
        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/next?quizId=${quizId}&index=${index.current}`, {withCredentials: true})
            .then(response => {
                setQuizData(response.data as QuizData);
                setUserAnswers(getUserAnswers(response.data))
                console.log(userAnswers)
            })
            .catch(error => {
                console.error("Error fetching quiz data:", error);
            });

        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/type/${quizId}`, {withCredentials: true})
            .then(response => {
                setQuizInfo(response.data as QuizInfo);
            })
            .catch(error => {
                console.error("Error fetching quiz info:", error);
            });

        const userAnswers:Array<boolean> = quizData?.answerOptions.map((option, index) => {
            return quizData?.userAnswers.find((elem) => elem == index) != undefined
        }) || []
        setUserAnswers(userAnswers)
    }, [location.state, quizId]);

    if (!quizData) {
        return <div className="loading">Loading...</div>;
    }

    function getUserAnswers(quizData:QuizData){
        let answers:Array<boolean> = []
        for (let i = 0; i < quizData.answerOptions.length; i++) {
            answers[i] = false
        }

        if (quizData.userAnswers != null){
            quizData.userAnswers.forEach((elem: number) => {
                console.log(elem)
                answers[elem-1] = true
            })
        }

        return answers
    }

    function submitAnswer(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        new FormData(event.currentTarget);
        if (quizData) {
            const formData = new FormData(event.currentTarget);
            // get selected answers index in int
            const selectedAnswers = Array.from(formData.values()).map((value: string | File) => {
                if (typeof value === 'string') {
                    return quizData.answerOptions.indexOf(value) + 1;
                } else {
                    return -1;
                }
            });

            createAxiosInstance().post(DEFAULT_API_URL + `/quiz/answer?quizId=${quizId}`, {
                index: index.current,
                answers: selectedAnswers
            }).then(() => {
                if (index != null && index.current < quizInfo.questionCount) {
                    index.current++;
                    createAxiosInstance().get(DEFAULT_API_URL + `/quiz/next?quizId=${quizId}&index=${index.current}`)
                        .then(response => {
                            setQuizData(response.data as QuizData);
                            setUserAnswers(getUserAnswers(response.data))
                        })
                        .catch(error => {
                            console.error("Error fetching next quiz data:", error);
                        });
                } else {
                    navigate(`/tests/${quizId}/result`)
                }
            }).catch(error => {
                console.error("Error submitting answer:", error);

            });
        }

        //remove the tics from the checkboxes and radios
        const inputs = document.getElementsByTagName('input');
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].type === 'radio' || inputs[i].type === 'checkbox') {
                inputs[i].checked = false;
            }

        }
    }

    function getPreviousQuestion() {
        index.current -= 1;
        createAxiosInstance().get(DEFAULT_API_URL + `/quiz/next?quizId=${quizId}&index=${index.current}`)
            .then((response) => {
                setQuizData(response.data as QuizData);
                setUserAnswers(getUserAnswers(response.data))
            })
    }

    function changeAnswer(index:number){
        userAnswers[index] = !userAnswers[index]
        console.log(userAnswers)
        setUserAnswers([...userAnswers])
    }

    return (
        <div className="outer-quiz-container">
            <div className="quiz-container">
                {index.current > 1 && (
                    <div className="back-arrow" onClick={getPreviousQuestion}>
                        <BackArrowIcon/>
                    </div>
                )}
                <h1 className="question" >{quizData.question}</h1>
                <form onSubmit={submitAnswer} className="answers-form">
                    {quizData.answerOptions.map((option, index) => (
                        <div key={index}  className="answer-option">
                            <input style={{cursor:"pointer"}}  type={quizData.answerType === "SINGLE" ? "radio" : "checkbox"} name="answer" checked={userAnswers[index]}
                                id={`option${index}`} value={option} onChange={() => changeAnswer(index)}/>
                            <label style={{cursor:"pointer"}}  htmlFor={`option${index}`}>{option}</label>
                        </div>
                    ))}

                    <button type="submit" className="submit-button">Подтвердить</button>
                </form>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "16px"
                }}>
                    {quizData.timeToEndInMills && (
                        <div style={{
                            display: "inline",
                            textAlign: "left",
                            fontSize: "24px"
                        }} className="progress">Оставшееся время: <Timer testId={quizId || ""}
                            deadline={new Date(new Date().getTime() + quizData.timeToEndInMills)}></Timer></div>
                    )}
                    <div style={{
                        display: "inline",
                        textAlign: "right",
                        fontSize: "24px"
                    }} className="progress">Вопрос {index.current} из {quizInfo.questionCount}</div>
                </div>
            </div>
        </div>
    );
};

export default QuizPage;
