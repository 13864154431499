import {useEffect, useState} from 'react';
import { NavigationComponent } from '../../components/NavigationComponent';
import { Background, Chapter, ChapterContent, ChapterContentBlock } from './style';
import {DEFAULT_API_URL} from "../../App";
import {Link} from "react-router-dom";

interface SubArticle {
    number: number;
    name: string;
    mongoId: string | null;
}

interface Article {
    number: number;
    part: string;
    name: string;
    subArticles: SubArticle[];
}

const Contents = () => {
    const [articles, setArticles] = useState<Article[]>([]);
    const [chosenChapter, setChosenChapter] = useState<number>(-1);
    const [opacityCondition, setOpacityCondition] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(DEFAULT_API_URL+'/article/all');
                const data = await response.json();
                setArticles(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handleMouseEnterChapter = (chapterNumber: number) => {
        setChosenChapter(chapterNumber);
        setTimeout(() => {
            setOpacityCondition(true);
        }, 300);
    };

    const handleMouseLeaveChapter = () => {
        setChosenChapter(-1);
        setOpacityCondition(false);
    };


    return(
        <div style={{...Background()}}>
            <div style={{height:'100%'}}>
                <NavigationComponent></NavigationComponent>
                <div style={{display: 'flex',height:'100%', padding:'125px 10% 125px 10%', flexDirection: 'column'}}>
                    <h2 style={{color:'white', fontSize: '48px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'}}>Содержание</h2>
                    {articles.map((chapter) => (
                        <div
                            key={chapter.number}
                            id={`chapter-${chapter.number}`}
                            onMouseEnter={() => handleMouseEnterChapter(chapter.number)}
                            onMouseLeave={handleMouseLeaveChapter}
                            style={{ ...Chapter(chapter.number, chosenChapter) }}
                        >
                            <h2 style={{ color: '#000', fontSize: '35px', fontStyle: 'normal', fontWeight: '600', lineHeight: 'normal', margin: '44px 44px 0 44px' }}>{chapter.part}</h2>
                            <h2 style={{ color: '#000', fontSize: '28px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal', margin: '0 44px 44px 44px' }}>{chapter.name}</h2>

                            <div style={{...ChapterContentBlock(chapter.number, chosenChapter, opacityCondition)}}>
                                <svg style={{position: 'relative', height: '5px'}} stroke='black' strokeWidth='3'>
                                    <path d='M0 0 L 500 0 '></path>
                                </svg>
                                {chapter.subArticles.map((subArticle) => (
                                    <div key={subArticle.number} style={{...ChapterContent()}}>
                                        <Link state={{title: chapter.name, subtitle: subArticle.name, chapter: chapter.part}} to={`/contents/${subArticle.mongoId}`}><p style={{fontSize: '24px', margin: '0'}}>{subArticle.name}</p></Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Contents