import {NavigationComponent} from '../../components/NavigationComponent'
import LOGO from './logo.svg';
import IMG1 from './original.jpg';
import IMG2 from './d555e3de17dc9b6e51323dc44f3ed04c.jpg'
import IMG3 from './7ed2e783bc54854cc158c70e7a7d1c6b.jpg'
// @ts-ignore
import VideoBackground from './background.mp4'
import './landing.css';
import {Background, First_block, First_img, Header, Logo_text_block, Second_block, Second_block_text, Second_img, Third_img} from './style';
import {useState} from "react";

const Landing = () => {
    const [videoEnded, setVideoEnded] = useState(false);

    const handleVideoEnd = () => {
        setVideoEnded(true);
    };

    return(
        <div style={{...Background()}}>
            {!videoEnded && (
                <video className="video" style={{zIndex:1}} autoPlay muted onEnded={handleVideoEnd} onClick={handleVideoEnd} id="videoBackground">
                    <source src={VideoBackground} type="video/mp4"/>
                    Your browser does not support HTML5 video.
                </video>
            )}
            <NavigationComponent isBackgroundTransparent={true}></NavigationComponent>
            <img style={{zIndex:0, ...Third_img()}} alt='Вторая мировая' src={IMG3}></img>
            <img style={{zIndex:0,...First_img()}} alt='Ледовое побоище' src={IMG1}></img>
            <img style={{zIndex:0,...Second_img()}} alt='Наполеон в Москве' src={IMG2}></img>
            <div style={{...First_block()}}>
                <img style={{width: '15vw'}} src={LOGO}></img>
                <div style={{...Logo_text_block()}}>
                    <p style={{ marginBottom:"-10px",  ...Logo_text_block()}}>ВОЕННАЯ</p>
                    <p style={{ marginTop:"-10px", ...Logo_text_block()}}>ИСТОРИЯ</p>
                </div>

            </div>

            <div style={{...Second_block()}}>
                <div style={{...Second_block_text()}}>
                    <p style={{...Header()}}>Содержание курса</p>
                    <p>Данный курс направлен на раcширение знаний в области военной истории и закрепления их посредством
                        различных материалов исторической хроники и тестов по ним.</p>
                </div>
            </div>

        </div>
    )
}

export default Landing