import { Link } from 'react-router-dom';
import {windowServices} from '../../modules/windowModules/windowModules';
import './style.css';
import {createAxiosInstance} from "../../utils/apiUtils";
import {DEFAULT_API_URL} from "../../App";
import {useEffect, useMemo, useRef, useState} from "react";

interface NavigationProps {
    isBackgroundTransparent?: boolean
}

export const NavigationComponent = (props:NavigationProps) => {
    const { height, width } = windowServices.useWindowDimensions();
    const isLogged = useRef<boolean>();
    const [isLocalMode, setIsLocalMode] = useState<boolean>(false);

    function getSession() {
        createAxiosInstance().get(`${DEFAULT_API_URL}/session`)
            .then((response) => {
                isLogged.current = response.data.userInfo !== null;
                setIsLocalMode(response.data.mode === "LOCAL")
                localStorage.setItem("testLoginEnabled", response.data.testEnabled)
                localStorage.setItem("isLogged", String(isLogged.current))
            })
    }

    useEffect(() => 
        getSession()
    ,[isLogged, isLocalMode])

    function logout() {
        createAxiosInstance().get(`${DEFAULT_API_URL}/session/logout`)
            .then(() => {
                localStorage.removeItem('jwt');
                window.location.href = '/';
            })
    }
    return(
        <div style={{
            position: "fixed",
            height: "70px",
            width: "100vw",
            backgroundColor: (props.isBackgroundTransparent ? "transparent" : "#00150B"),
            zIndex: "2",
        }}>
        <svg xmlns="http://www.w3.org/2000/svg" style={{position:'fixed'}} width={width} height={10} viewBox={'0 0 '+String(width)+' 10'} fill="none" >
            <path d={`M0 0 H ${String(width)} V 10  H ${String(-width)} V -10   Z `} fillRule="evenodd" fill="#D2AE84" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" style={{left: width -10, position:'fixed'}} width={10} height={height} viewBox={'0 0 20'+String(height)} fill="none" >
            <path d={"M0 0 H 10 V" + String(height) + " H -10 Z " } fillRule="evenodd" fill="#D2AE84"  />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" style={{top: height - 10, position:'fixed'}} width={width} height={10} viewBox={'0 0 '+String(width)+' 10'} fill="none" >
            <path d={"M0 0 H" + String(width) + " V 10 H" + String(-width) + " V -10 Z " } fillRule="evenodd" fill="#D2AE84" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" style={{position:'fixed'}} width={10} height={height} viewBox={'0 0 10'+String(height)} fill="none" >
            <path d={"M0 0 H 10 V " + String(height)  + " H -10 V "+ String(-height)  + " Z " } fillRule="evenodd" fill="#D2AE84" />
        </svg>
        <Link to="/">
            <svg xmlns="http://www.w3.org/2000/svg" style={{left: 125, top: 9.5}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
                <path d={"M0 0 L 25 50  H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'  />
                <text x="68" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Главная</text>
            </svg>
        </Link>
        <Link to="/contents">
            <svg xmlns="http://www.w3.org/2000/svg" style={{left: 315, top: 9.5}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
                <path d={"M0 0 L 25 50  H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'  />
                <text x="51" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Содержание</text>
            </svg>
        </Link>
        <Link to="/glossary">
        <svg xmlns="http://www.w3.org/2000/svg" style={{left: 505, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg' >
            <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
            <text x="59" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Глоссарий</text>
        </svg>
        </Link>
        <Link to="/tests">
        <svg xmlns="http://www.w3.org/2000/svg" style={{left: 695, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
            <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
            <text x="74" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Тесты</text>
        </svg>
        </Link>
        <Link to="/schemas">
            <svg xmlns="http://www.w3.org/2000/svg" style={{left: 885, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
                <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
                <text x="42" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Альбомы схем</text>
            </svg>
        </Link>
    {!isLocalMode && (
        <Link to="/videos">
                <svg xmlns="http://www.w3.org/2000/svg" style={{left: 1075, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
                    <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
                    <text x="74" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Видео</text>
                </svg>
        </Link>
    )}
        <Link to="/cabinet">
        <svg xmlns="http://www.w3.org/2000/svg" style={{left: width - 325, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
            <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
            <text x="65" y="30" fill='#000'  style={{textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Кабинет</text>
        </svg>
        </Link>

        { isLogged.current ?
            <>
                <svg onClick={logout} xmlns="http://www.w3.org/2000/svg" style={{cursor:'pointer',left: width - 515, top: 10}} width={190} height={50} viewBox={'0 0 190 50'} fill="none" className='navigation-button-svg'>
                    <path d={"M0 0 L 25 50 H165 L 190 0  z " } fillRule="evenodd" fill="#D2AE84" className='navigation-button'/>
                    <text x="69" y="30" fill='#000' style={{ textAlign: 'center', fontSize: '16px',fontStyle: 'normal',fontWeight: '400',lineHeight: 'normal'}}>Выйти</text>
                </svg>
            </>
            :
            <></>
        }

        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox={'0 0 20 20'} fill="none" >
            <path d={"M 10 20 C 0 0 10 10 20 10 " } fillRule="evenodd"/>
        </svg>
        </div>
    );
}  