import React, { useEffect, useState } from "react";
import { NavigationComponent } from "../../components/NavigationComponent";
import { Background } from "./style";
import { GlossarySearchGenerator } from "../../components/SearchGenerator/glossarySearchGenerator";
import { getGlossaries, GlossaryWithTotalBackend } from "../../backend/glossary";
import { handleInputChange } from "../../components/SearchGenerator/common";
import { Pagination } from "../../components/Pagination/pagination";
import './glossary.css';
import { createAxiosInstance } from "../../utils/apiUtils";
import { DEFAULT_API_URL } from "../../App";
import axios, { AxiosError } from "axios";

interface GlossaryType {
    name: string;
    description: string;
}

const Glossary: React.FC = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [glossaryTypes, setGlossaryTypes] = useState<Array<GlossaryType>>([]);
    const [group, setSelectedValue] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [glossaryWithTotal, setGlossaryWithTotal] = useState<GlossaryWithTotalBackend | null>(null);

    useEffect(() => {
        const fetchGlossaryTypes = async () => {
            try {
                const response = await createAxiosInstance().get(`${DEFAULT_API_URL}/glossary/types`);
                setGlossaryTypes(response.data);
                setSelectedValue(response.data[0].name);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    alert(error.response?.data.message);
                } else {
                    alert("An unexpected error occurred");
                }
            }
        };

        fetchGlossaryTypes();
    }, []);

    useEffect(() => {
        const fetchGlossaries = async () => {
            try {
                const data = await getGlossaries(page, rowsPerPage, searchTerm, group);
                setGlossaryWithTotal(data);
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    console.error('Failed to fetch glossaries:', error.response?.data.message);
                } else {
                    console.error('An unexpected error occurred');
                }
            }
        };

        fetchGlossaries();
    }, [page, rowsPerPage, searchTerm, group]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedValue(event.target.value);
        setPage(0);
    };

    if (!glossaryWithTotal) return <div>Loading...</div>;

    return (
        <div style={{ ...Background() }}>
            <div>
                <NavigationComponent />
                <div style={{ display: 'flex', padding: '125px 10% 0 10%', flexDirection: 'column' }}>
                    <h2 style={{
                        color: 'white',
                        fontSize: '48px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: 'normal'
                    }}>Глоссарий</h2>
                    <div>
                        <select value={group} onChange={handleSelectChange} className="form-select">
                            {glossaryTypes.map((glossary) => (
                                <option key={glossary.name} value={glossary.name}>{glossary.description}</option>
                            ))}
                        </select>
                    </div>
                    <input
                        onChange={(event) => setSearchTerm(handleInputChange(event))}
                        style={{
                            color: 'black',
                            backgroundColor: '#D2AE84',
                            borderRadius: '30px',
                            height: '50px',
                            textIndent: '20px',
                            fontSize: '20px'
                        }}
                    />
                    <Pagination
                        page={page}
                        rowsPerPage={rowsPerPage}
                        totalElements={glossaryWithTotal.totalElements}
                        handleChangePage={handleChangePage}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <GlossarySearchGenerator
                        currentGlossary={glossaryWithTotal.glossary}
                        searchTerm={searchTerm}
                    />
                </div>
            </div>
        </div>
    );
};

export default Glossary;
